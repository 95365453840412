'use client'

import { ArrowLeftIcon, ArrowRightIcon, ZoomInIcon, ZoomOutIcon } from '@radix-ui/react-icons'
import dynamic from 'next/dynamic'
// import * as worker from 'next-public-site/public/pdfjs/pdf.worker.min.mjs'
import type { PDFDocumentProxy } from 'pdfjs-dist'
// import dynamic from 'next/dynamic'
import { FC, useEffect, useState } from 'react'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { css, cva } from 'styled-system/css'

import { CustomDivider } from '../CustomDivider'
import { CustomFlex } from '../CustomFlex'
import { Icon } from '../Icon'
import { Text } from '../Text'

const Document = dynamic(() => import('react-pdf').then(mod => mod.Document))
const Page = dynamic(() => import('react-pdf').then(mod => mod.Page))

// const { Document, Page, pdfjs } = dynamic(() => import('react-pdf').then(mod => ({Document: mod.Document, Page: mod.Page})))

export const PDF: FC<{
  file: string | File | null
  initialScale?: number
}> = ({ file, initialScale = 1 }) => {
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [scale, setScale] = useState<number>(initialScale)

  // TODO: this should be from the same domain even in custom domains
  useEffect(() => {
    const loadPdfjs = async () => {
      const { pdfjs } = await import('react-pdf')
      pdfjs.GlobalWorkerOptions.workerSrc = `/_static/pdf-worker.min.mjs`
    }
    loadPdfjs()
  }, [])

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy): void {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function scaleUp() {
    setScale(prevScale => prevScale + 0.25)
  }

  function scaleDown() {
    setScale(prevScale => prevScale - 0.25)
  }

  return (
    <div className={wrapper}>
      {!!numPages && (
        <CustomFlex className={pageControls} gap="2">
          {numPages > 1 && (
            <>
              <CustomFlex>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className={buttonLeft({
                    disabled: pageNumber <= 1,
                  })}>
                  <Icon reactIcon={<ArrowLeftIcon />} size={24} pointer={!(pageNumber <= 1)} />
                </button>
                <Text
                  variant="body2"
                  css={{
                    whiteSpace: 'nowrap',
                    lineHeight: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: '$2',
                  }}>
                  {pageNumber} of {numPages}
                </Text>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  className={buttonRight({
                    disabled: pageNumber >= numPages,
                  })}>
                  <Icon reactIcon={<ArrowRightIcon />} size={24} pointer={!(pageNumber >= numPages)} />
                </button>
              </CustomFlex>
              <CustomDivider orientation="vertical" />
            </>
          )}
          <CustomFlex>
            <button type="button">
              <Icon
                reactIcon={<ZoomOutIcon />}
                size={24}
                className={buttonLeft({ disabled: false })}
                onClick={scaleDown}
              />
            </button>
            <button type="button">
              <Icon
                reactIcon={<ZoomInIcon />}
                size={24}
                className={buttonRight({ disabled: false })}
                onClick={scaleUp}
              />
            </button>
          </CustomFlex>
        </CustomFlex>
      )}
      <div className={container}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} renderAnnotationLayer={true} scale={scale} />
        </Document>
      </div>
    </div>
  )
}

const wrapper = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
})

const container = css({
  height: '100%',
  overflow: 'auto',
  // width: 'fit-content',
  '& canvas': {
    margin: '[0 auto]',
    // width: '100% !important',
    // height: 'auto !important',
  },
  '& .textLayer': {
    height: 'fit-content !important',
  },
  '& .annotationLayer': {
    height: 'auto !important',
  },
  '& .react-pdf__Page': {
    width: 'fit-content',
    margin: '0 auto',
  },
})

const pageControls = css({
  display: 'flex',
  // position: 'absolute',
  // bottom: '$8',
  // transform: 'translateX(-50%)',
  // left: '50%',
  // zIndex: '10',
  margin: '0 auto',
  boxShadow: 'md',
  opacity: '0.95',
  backgroundColor: '$gs12',
  borderColor: '$gs12',
  color: '$gs1',
  borderRadius: '$4',
  width: 'fit-content',
  borderTopWidth: '15px',
  borderBottomWidth: '15px',
})

const buttonCommon = {
  border: 'none',
  height: '44px',
  minWidth: '44px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
} as const

const buttonDisabledCommon = {
  color: '$gs8',
  cursor: 'default',
} as const

const buttonNotDisabledCommon = {
  color: '$gs1',
  cursor: 'pointer',
  transitionProperty: 'background-color',
  transitionDuration: '$normal',
  '&:hover': {
    transitionProperty: 'background-color',
    transitionDuration: '$normal',
    backgroundColor: '$gs10',
  },
} as const

const buttonRight = cva({
  base: {
    ...buttonCommon,
    // borderTopRightRadius: '$4',
    // borderBottomRightRadius: '$4',
  },
  variants: {
    disabled: {
      true: {
        ...buttonDisabledCommon,
      },
      false: {
        ...buttonNotDisabledCommon,
      },
    },
  },
})

const buttonLeft = cva({
  base: {
    ...buttonCommon,
    // borderTopLeftRadius: '$4',
    // borderBottomLeftRadius: '$4',
  },
  variants: {
    disabled: {
      true: {
        ...buttonDisabledCommon,
      },
      false: {
        ...buttonNotDisabledCommon,
      },
    },
  },
})
